.contextBody {
  background:rgba(255,255,255,0.1);
  border-radius: 10px;
  width: 100%;
  margin-top: 25vh;
  height: 50vh;
}

.App-logo {
  margin-top: -3vh;
  margin-left: 1.5vh;
  height: 6vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}
