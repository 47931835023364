
.user-body {
  float: right;
  margin-right: -40px;
}

.logo {
  margin-left: -40px;
}

.logo-svg {
  height: 45px;
  width: 45px;
  vertical-align: middle;
}

.logo-text {
  font-size: 26px;
  color: white;
  font-weight: bold;
  vertical-align: middle;
}

