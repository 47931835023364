.init-show{
    padding-top: 50vh;
    display: block;
}

.init-hidden{
    padding-top: 50vh;
    display: none;
}

.app-show{
    display: block;
}

.app-hidden{
    display: none;
}
